<template>
  <div class="d-flex flex-column mx-3 py-3" v-if="isFinanceModuleEnabled">
    <div class="spacer"></div>
    <ion-label class="fw-500 text-gray-800 fs-3 py-2">{{ $t('invoices_payment_history') }}</ion-label>
    <div class="d-flex justify-space-between items-center">
      <ion-card class="mx-auto w-50 mr-1 py-1 card-invoice-account" @click="onTopUpCredit">
        <ion-grid>
          <ion-row>
            <ion-col size="12">
              <ion-row>
                <ion-col>
                  <ion-text class="text-primary">{{ $t('available_credits') }}</ion-text>
                </ion-col>
                <ion-col size="auto">
                  <ion-icon
                    :icon="addCircleOutline"
                    class="text-primary fs-4"
                    style="position: absolute; right: 0.2rem"
                  ></ion-icon>
                </ion-col>
              </ion-row>
              <ion-row>
                <ion-text class="pl-1 fw-bold text-primary">{{
                  priceFormatter(currencySymbol, availableCreditAmount)
                }}</ion-text>
              </ion-row>
            </ion-col>
          </ion-row>
        </ion-grid>
      </ion-card>
      <ion-card class="mx-auto w-50 ml-1 py-1 card-invoice-account">
        <ion-grid>
          <ion-row>
            <ion-col size="12">
              <ion-row>
                <ion-col>
                  <ion-text color="danger">{{
                    $t('unpaid_invoices', { unpaidInvoiceAmount: totalUnpaidInvoices })
                  }}</ion-text>
                </ion-col>
              </ion-row>
              <ion-row>
                <ion-text color="dark" class="pl-1 fw-bold">{{
                  priceFormatter(currencySymbol, unpaidAmount)
                }}</ion-text>
              </ion-row>
            </ion-col>
          </ion-row>
        </ion-grid>
      </ion-card>
    </div>
    <div class="d-flex w-100 py-2 items-center justify-center mx-auto">
      <ion-button
        class="text-white w-100 rounded-lg"
        :color="hasInvoiceUnpaid ? 'danger' : 'primary'"
        @click="enterPayment"
      >
        <ion-label>{{ hasInvoiceUnpaid ? $t('make_payment') : $t('wallet.view_all') }}</ion-label>
      </ion-button>
    </div>
    <div class="spacer"></div>
  </div>
</template>
<script>
import { ACTIONS } from '@/modules/sale/store/payment/actions';
import { priceFormatter } from '@/utils/';
import { addCircleOutline } from 'ionicons/icons';
import { defineComponent, ref } from 'vue';
import { useRouter } from 'vue-router';
import { createNamespacedHelpers, useStore } from 'vuex';
const { mapGetters, mapActions } = createNamespacedHelpers('sale/payment');
export default defineComponent({
  name: 'CardInvoiceAccount',
  inject: ['$storage'],
  emits: ['onTopUpCreditToggle'],
  setup(_, { emit }) {
    const router = useRouter();
    const store = useStore();
    const enterPayment = () => {
      router.push(`/sale/invoices/select-invoices`);
    };
    return {
      enterPayment,
      addCircleOutline,
      priceFormatter,
      user: ref(null),
      company: ref(null),
      onTopUpCredit: () => {
        store.dispatch(`sale/payment/${ACTIONS.SET_ENTRY_TOP_UP_CREDIT}`, `ACCOUNT_PAGE`);
        emit('onTopUpCreditToggle');
      }
    };
  },
  computed: {
    ...mapGetters(['xeroCustomer', 'totalUnpaidInvoices', 'status', 'isFinanceModuleEnabled']),
    currencySymbol() {
      return this.user && this.user.currency_symbol;
    },
    availableCreditAmount() {
      const creditAmount =
        Number(this.xeroCustomer?.overpayment_amount || 0) +
        Number(this.xeroCustomer?.credit_notes_amount || 0);
      if (creditAmount < 0) {
        return 0;
      } else {
        return creditAmount;
      }
    },
    unpaidAmount() {
      return Number(this.xeroCustomer?.outstanding_amount || 0);
    },
    hasInvoiceUnpaid() {
      return this.unpaidAmount > 0;
    }
  },

  async mounted() {
    this.user = await this.$storage.getUser();
    this.company = await this.$storage.getSelectedCompany();
    await this.checkIsFinanceModuleEnabled();
    if (!this.isFinanceModuleEnabled) return;
    this.getXeroCustomer();
    this.getTotalUnpaidInvoices();
  },
  methods: {
    ...mapActions([
      ACTIONS.GET_XERO_CUSTOMER,
      ACTIONS.GET_TOTAL_UNPAID_INVOICES,
      ACTIONS.CHECK_IS_FINANCE_MODULE_ENABLED
    ]),
    async checkIsFinanceModuleEnabled() {
      await this[ACTIONS.CHECK_IS_FINANCE_MODULE_ENABLED]({
        tenantId: this.user.tenant.id,
        customerId: this.company.id
      });
    },
    async getXeroCustomer() {
      await this[ACTIONS.GET_XERO_CUSTOMER]({
        tenantId: this.user.tenant.id,
        customerId: this.company.id
      });
    },
    async getTotalUnpaidInvoices() {
      await this[ACTIONS.GET_TOTAL_UNPAID_INVOICES]({
        tenantId: this.user.tenant.id,
        customerId: this.company.id
      });
    }
  }
});
</script>
<style lang="scss" scoped>
.card-invoice-account {
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.18);
  border-radius: 8px;
}
.w-49 {
  width: 46%;
}
.rounded-lg {
  height: 2.4em;
}
</style>
